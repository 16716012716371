const SUBJECTS = {
  OVERALL: {
    _id: "overall",
    name: "Overall",
    title: "Overall",
    color: "#364FC7",
    gradient:
      "linear-gradient(270deg, rgba(231, 176, 8, 0.10) 0%, rgba(231, 176, 8, 0.01) 100%)",
    gradientDark:
      "linear-gradient(270deg, rgba(231, 176, 8, 0.10) 0%, rgba(231, 176, 8, 0.01) 100%)",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/overall_2.svg",
  },
  PHYSICS: {
    name: "Physics",
    color: "#37B24D",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), var(--Physics, #37B24D)",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), var(--Physics, #37B24D)",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/physics.svg",
  },
  CHEMISTRY: {
    name: "Chemistry",
    color: "#FF8C33",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), var(--Chemistry, #FF8C33)",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), var(--Chemistry, #FF8C33)",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/chemistry.svg",
  },
  MATHS: {
    name: "Mathematics",
    color: "#0086FF",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), var(--Maths, #0086FF)",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), var(--Maths, #0086FF)",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/maths.svg",
  },
  MATHEMATICS: {
    name: "Mathematics",
    color: "#0086FF",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), var(--Maths, #0086FF)",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), var(--Maths, #0086FF)",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/maths.svg",
  },
  BIOLOGY: {
    name: "Biology",
    color: "#BA05FA",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #BA05FA",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #BA05FA",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/biology.svg",
  },
  ENGLISH: {
    name: "English",
    color: "#283EFF",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #283EFF",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #283EFF",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/english.svg",
  },
  "LOGICAL REASONING": {
    name: "Logical Reasoning",
    color: "#F76707",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #F76707",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #F76707",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/logical-reasoning.svg",
  },
  "ENGLISH AND LOGICAL REASONING": {
    name: "English and Logical Reasoning",
    color: "#F76707",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #F76707",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #F76707",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/logical-reasoning.svg",
  },
  ALL_ANALYTICS: {
    name: "All Analytics",
    color: "#2C372E",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #2C372E",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #2C372E",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/analytics.svg",
  },
  LINGUISTICS: {
    name: "Linguistics",
    color: "#283EFF",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #283EFF",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #283EFF",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/linguistics.svg",
  },
  GENERAL_TEST: {
    name: "General Test",
    color: "#0086FF",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), var(--Maths, #0086FF)",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), var(--Maths, #0086FF)",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/general-test.svg",
  },
  ALGEBRA_AND_GEOMETRY: {
    name: "Algebra & Geometry",
    color: "#AE3EC9",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #AE3EC9",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #AE3EC9",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/algebra-and-geometry.svg",
  },
  ARITHMETIC_AND_BASIC_PCM: {
    name: "Arithmetic and Basic PCM",
    color: "#74B816",
    gradient:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.90) 100%), #74B816",
    gradientDark:
      "linear-gradient(180deg, rgba(29, 40, 58, 0.60) 0%, rgba(29, 40, 58, 0.90) 100%), #74B816",
    icon: "https://cdn.quizrr.in/web-assets/analysis-v3/subjects/arithmetic-and-basic-pcm.svg",
  },
};

const QUESTION_TYPES = {
  singleCorrect: {
    id: 3,
    title: "Single Correct Qs",
    icon: "/icons/analysis/single-correct.svg",
    darkIcon: "/icons/analysis/single-correct-dark.svg",
    shortName: "singleCorrect",
    color: "#3288D7",
    gradient: "",
    gradientDark: "",
  },
  numerical: {
    id: 2,
    title: "Numerical Qs",
    icon: "/icons/analysis/numerical-correct.svg",
    darkIcon: "/icons/analysis/numerical-correct-dark.svg",
    shortName: "numerical",
    color: "#3288D7",
    gradient: "",
    gradientDark: "",
  },
  multipleCorrect: {
    id: 1,
    title: "Multiple Correct Qs",
    icon: "/icons/analysis/multiple-correct.svg",
    darkIcon: "/icons/analysis/multiple-correct-dark.svg",
    shortName: "multipleCorrect",
    color: "#3288D7",
    gradient: "",
    gradientDark: "",
  },
};

const ENDPOINTS = {
  TEST: "/api/test",
  ANALYSISV3: {
    OVERVIEW: "/api/test/analysis/v2",
  },
  PACK_ANALYSIS: {
    OVERVIEW: "/api/packs/v2/analysis",
  },
};

const OVERVIEW = {
  perfect: {
    color: "#37B24D",
    icon: "/icons/analysis/perfect.svg",
  },
  wasted: {
    color: "#EF4343",
    icon: "/icons/analysis/wasted.svg",
  },
  confused: {
    color: "#FF8C33",
    icon: "/icons/analysis/confused.svg",
  },
  overtime: {
    color: "#65758B",
    icon: "/icons/analysis/overtime.svg",
  },
};

const STATUS = {
  CORRECT: {
    color: "#37B24D",
    gradient:
      "linear-gradient(270deg, rgba(22, 162, 73, 0.15) 0%, rgba(22, 162, 73, 0.02) 100%)",
    icon: "/icons/analysis/perfect.svg",
  },
  TOTAL: {
    color: "#FF8C33",
    gradient: "var(--status-total-gradient)",
    icon: "/icons/analysis/confused.svg",
  },
  INCORRECT: {
    color: "#EF4343",
    gradient:
      "linear-gradient(270deg, rgba(239, 67, 67, 0.10) 0%, rgba(239, 67, 67, 0.01) 100%)",
    icon: "/icons/analysis/wasted.svg",
  },
  UNATTEMPTED: {
    color: "rgba(160,163,184,1.02)",
    gradient:
      "linear-gradient(270deg, rgba(148, 163, 184, 0.12) 0%, rgba(148, 163, 184, 0.02) 100%)",
    icon: "/icons/analysis/not-attempted.svg",
  },
  NOT_VISITED: {
    color: "#65758B",
    gradient: "var(--status-not-visited-gradient)",
    icon: "/icons/analysis/overtime.svg",
  },
};

const QSTATUS = {
  CORRECT: {
    color: "#16A249",
    gradient:
      "linear-gradient(270deg, rgba(22, 162, 73, 0.15) 0%, rgba(22, 162, 73, 0.02) 100%)",
    icon: "/icons/analysis/correct.svg",
  },
  INCORRECT: {
    color: "#EF4343",
    gradient:
      "linear-gradient(270deg, rgba(239, 67, 67, 0.10) 0%, rgba(239, 67, 67, 0.01) 100%)",
    icon: "/icons/analysis/incorrect.svg",
  },
  AVERAGE: {
    color: "#E1992D",
    gradient:
      "linear-gradient(270deg, rgba(231, 176, 8, 0.10) 0%, rgba(231, 176, 8, 0.01) 100%)",
    icon: "/icons/analysis/average.svg",
  },
  NOT_ATTEMPTED: {
    color: "#364FC7",
    gradient:
      "linear-gradient(270deg, rgba(148, 163, 184, 0.12) 0%, rgba(148, 163, 184, 0.02) 100%)",
    icon: "/icons/analysis/not-attempted.svg",
  },
  NOT_VISITED: {
    color: "#48566A",
    gradient:
      "linear-gradient(270deg, rgba(72, 86, 106, 0.12) 0%, rgba(72, 86, 106, 0.02) 100%)",
    icon: "/icons/analysis/not-attempted.svg",
  },
};

const QUESTION_STATUS_STRING = {
  ANSWERED_CORRECT: "Answered Correct",
  ANSWERED_WRONG: "Answered Wrong",
  SKIPPED: "Skipped",
  MARKED_FOR_REVIEW: "Marked for Review",
  UNMARKED_FOR_REVIEW: "Unmarked for Review",
  NOT_VISITED: "notVisited",
  NOT_ATTEMPTED: "notAnswered",
};

const QUESTION_SENTIMENT = {
  MOST_IMPORTANT: "Most Important",
  PAINFUL: "Painful",
  COUNT: "Count",
};

const ICONS = {
  Skipped: "/icons/analysis/skipped.svg",
  "Answered Correct": "/icons/analysis/correct.svg",
  "Answered Wrong": "/icons/analysis/wrong.svg",
  "Marked for Review": "/icons/analysis/marked-for-review.svg",
  "Unmarked for Review": "/icons/analysis/un-marked-for-review.svg",
  notVisited: "/icons/analysis/not-visited.svg",
  notAnswered: "/icons/analysis/not-attempted.svg",
  Painful: "/icons/analysis/painful.svg",
};

const IMAGES = {
  MUST_DO: "/img/analysis/must-do.svg",
  PAINFUL: "/img/analysis/painful.svg",
  QUESTION_JOURNEY_CORRECT:
    "https://cdn.quizrr.in/web-assets/analysis-v3/question-journey/correct.svg",
  QUESTION_JOURNEY_FAST_FORWARD:
    "https://cdn.quizrr.in/web-assets/analysis-v3/question-journey/fast-forward.svg",
  QUESTION_JOURNEY_NOT_ATTEMPTED:
    "https://cdn.quizrr.in/web-assets/analysis-v3/question-journey/not-attempted.svg",
  QUESTION_JOURNEY_NOT_ATTEMPTED_1:
    "https://cdn.quizrr.in/web-assets/analysis-v3/question-journey/not-attempted-1.svg",
  QUESTION_JOURNEY_WRONG:
    "https://cdn.quizrr.in/web-assets/analysis-v3/question-journey/wrong.svg",
  NOT_VISITED: "/icons/analysis/not-visited.svg",
  NOT_ATTEMPTED: "/icons/analysis/not-attempted.svg",
  QUESTION_JOURNEY_FLAG_3:
    "https://cdn.quizrr.in/web-assets/analysis-v3/question-journey/flag-03.svg",
};



export {
  SUBJECTS,
  ENDPOINTS,
  OVERVIEW,
  STATUS,
  QSTATUS,
  QUESTION_TYPES,
  IMAGES,
  ICONS,
  QUESTION_SENTIMENT,
  QUESTION_STATUS_STRING,
};